* {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.header_search {
    position: fixed;
    width: 100%;
    height: 56px;
    background-color: #2874f0;
    z-index: 20;
    display: flex;
    align-items: center;
    flex: auto;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    padding: 0 100px;
    justify-content: space-between;
}

.header_search .logo {
    text-align: center;
    max-width: 160px;
    max-height: 160px;
    cursor: pointer;
    z-index: 1;
}

.header_search .logo img {
    width: 50%;
}

.header_input_search {
    display: flex;
    position: relative;
    width: 100%;
    flex: 40% 1;
}

.header_input_search input{
    padding: 0 16px;
    border-radius: 2px 0 0 2px;
    border: 0;
    outline: 0 none;
    font-size: 14px;
    height: 36px;
    width: 100%;
    min-width: 400px;
}

.header_input_search button {
    border: 1px solid #fff;
    background-color: #fff;
    height: 36px;
    width: 44px;
    border-radius: 2px;
    cursor: pointer;
    padding: 4px 12px 0 8px;
}

.header_input_search i {
    color: #2874f0;
    font-size: 16px;
}

.login_btn_search_submit {
    color: #2874f0;
    font-weight: 500;
    background-color: #fff;
    cursor: pointer;
    border-radius: 2px;
    padding: 3px 40px;
    border: 1px solid #dbdbdb;
    text-decoration: none;
    margin: 0 20px;
}

.login_btn_search {
    position: relative;
}

.login_btn_search:hover .login_btn_option{
    display: block;
}

.login_btn_option::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
    position: absolute;
    top: -10px;
    left: 108px;
}
.login_btn_option::before {
    content: "";
    width: 240px;
    height: 20px;
    position: absolute;
    top: -14px;
    left: 0;
    background-color: transparent;
}

.login_btn_option {
    position: absolute;
    display: none;
    color: #212121;
    font-size: 13px;
    list-style: none;
    background-color: #fff;
    top: 34px;
    left: -38px;
    box-shadow: 0 3px 16px 0 rgb(0 0 0 / 11%)
}

.login_btn_item {
    padding: 16px 20px;
    width: 100%;
    min-width: 240px;
    border-bottom: 1px solid #f0f0f0;
}

.login_btn_item.my_profile:hover, .login_btn_item:not(:first-child):hover {
    background-color: #f0f0f0;
    cursor: pointer;
}

.login_btn_item i {
    margin-right: 10px;
    color: #2874f0;
}

/* More */

.seach_header_more {
    position: relative;
}

.seach_header_more:hover .more_btn_option{
    display: block;
}

.more_btn_option::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
    position: absolute;
    top: -10px;
    left: 108px;
}
.more_btn_option::before {
    content: "";
    width: 240px;
    height: 20px;
    position: absolute;
    top: -14px;
    left: 0;
    background-color: transparent;
}

.more_btn_option {
    position: absolute;
    display: none;
    text-align: left;
    color: #212121;
    font-size: 13px;
    list-style: none;
    background-color: #fff;
    top: 31px;
    left: -53px;
    box-shadow: 0 3px 16px 0 rgb(0 0 0 / 11%)
}

.more_btn_item {
    padding: 16px 20px;
    width: 100%;
    min-width: 240px;
    border-bottom: 1px solid #f0f0f0;
}

.more_btn_item:hover {
    background-color: #f0f0f0;
    cursor: pointer;
}

.more_btn_item i {
    margin-right: 10px;
    color: #2874f0;
}

/*  */




.search_header_cart span{
    position: relative;
}

.cart_quantity {
    position: absolute !important;
    left: -12px;
    top: -12px;
    text-align: center;
    border-radius: 7px;
    width: 18px;
    height: 18px;
    background-color: #ff6161;
    border: 1px solid #fff;
    font-weight: 400;
    color: #f0f0f0;
    line-height: 16px;
    font-size: 12px;
}

.become_seller,
.seach_header_more,
.search_header_cart {
    width: 100%;
    text-align: center;
    flex: 18% 1;
    cursor: pointer;
}


.modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 30;
}

.login_form {
    position: relative;
}

.login_form > span i {
    font-size: 30px;
    color: #fff;
}

.login_form > span {
    position: absolute;
    cursor: pointer;
    top: -6;
    right: -24px;
}

.login_container {
    width: 100%;
    display: flex;
    color: #212121;
    margin: 0 auto;
    border-radius: 2px;
    height: 528px;
    max-width: 750px;
    min-width: 650px;
}
.login_image {
    background-image: url(https://res.cloudinary.com/de4aiajqg/image/upload/v1652710961/avatar/login_img_c4a81e_wg7kqo.png);
    background-position: center 85%;
    background-repeat: no-repeat;
    background-color: #2874f0;
    color: #fff;
    padding: 40px 33px;
    font-size: 15px;
    width: 40%;
    display: flex;
    flex-direction: column;
}

.login_image span:first-child {
    font-size: 24px;
    margin-bottom: 20px;
}

.login_content {
    padding: 56px 35px 16px;
    width: 60%;
}

.input_email,
.input_password
{
    position: relative;
    margin-bottom: 26px;
    font-size: 16px;
    color: #212121;
    pointer-events: auto;
    width: 100%;
}

.input_email input,
.input_password input
{
    padding: 8px 10px 10px 0;
    width: 100%;
    color: #000;
    font-size: 14px;
    background: #fff;
    border: none;
    border-bottom: 1px solid #e0e0e0;
    outline: none;
}

.input_password input {
    position: relative;
}

.input_password span {
    position: absolute;
    right: 0;
    top: 20px;
    font-size: 14px;
    color: #2874f0;
    font-weight: 500;
    cursor: pointer;
}

.login_policy {
    font-size: 12px;
}

.login_policy span span {
    color: #2874f0;
}

.login_btn {
    background-color: #fb641b;
    color: #fff;
    text-align: center;
    font-size: 14px;
    padding: 14px 20px;
    margin: 10px 0;
    cursor: pointer;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
}

.login_OTP {
    background-color: #fff;
    color: #2874f0;
    text-align: center;
    font-size: 14px;
    padding: 14px 20px;
    margin: 10px 0 30px 0;
    cursor: pointer; 
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
}

.login_or {
    color: #878787;
    text-align: center;
}

.login_social {
    display: flex;
    color: #2874f0;
    align-items: center;
    justify-content: space-around;
}

.login_social_item {
    cursor: pointer;
    padding: 10px 16px;
    border-radius: 100%;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);

}

.login_social_item:nth-child(2) {
    padding: 10px 18px;
}

.create_account {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #2874f0;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 10px;
    cursor: pointer;
}

.my-facebook-button-class {
    background-color: rgb(255, 255, 255);
    display: inline-flex;
    align-items: center;
    box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px;
    padding: 11px;
    border-radius: 2px;
    border: 1px solid transparent;
    font-size: 14px;
    font-weight: 500;
    color: #1094f4;
    cursor: pointer;
}

.my-facebook-button-class i {
    font-size: 20px;
    margin-right: 10px;
}
.modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 30;
}

.signup_form {
    position: relative;
}

.signup_form > span i {
    font-size: 30px;
    color: #fff;
}

.signup_form > span {
    position: absolute;
    cursor: pointer;
    top: -6;
    right: -24px;
}

.signup_container {
    width: 100%;
    display: flex;
    color: #212121;
    margin: 0 auto;
    border-radius: 2px;
    height: 528px;
    max-width: 750px;
    min-width: 650px;
}
.signup_image {
    background-image: url(https://res.cloudinary.com/de4aiajqg/image/upload/v1652710961/avatar/login_img_c4a81e_wg7kqo.png);
    background-position: center 85%;
    background-repeat: no-repeat;
    background-color: #2874f0;
    color: #fff;
    padding: 40px 33px;
    font-size: 15px;
    width: 40%;
    display: flex;
    flex-direction: column;
}

.signup_image span:first-child {
    font-size: 24px;
    margin-bottom: 20px;
}

.signup_content {
    padding: 4px 35px 16px;
    width: 60%;
}

.input_email,
.input_password
{
    position: relative;
    margin-bottom: 26px;
    font-size: 16px;
    color: #212121;
    pointer-events: auto;
    width: 100%;
}

.input_email input,
.input_password input,
.input_confirm_password input
{
    padding: 8px 10px 10px 0;
    width: 100%;
    color: #000;
    font-size: 14px;
    background: #fff;
    border: none;
    border-bottom: 1px solid #e0e0e0;
    outline: none;
}

.input_password input {
    position: relative;
}

.input_password span {
    position: absolute;
    right: 0;
    top: 20px;
    font-size: 14px;
    color: #2874f0;
    font-weight: 500;
    cursor: pointer;
}

.signup_policy {
    font-size: 12px;
    margin-top: 20px;
}

.signup_policy span span {
    color: #2874f0;
}

.signup_btn {
    background-color: #fb641b;
    color: #fff;
    text-align: center;
    font-size: 14px;
    padding: 14px 20px;
    margin: 10px 0;
    cursor: pointer;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
}

.signup_OTP {
    background-color: #fff;
    color: #2874f0;
    text-align: center;
    font-size: 14px;
    padding: 14px 20px;
    margin: 10px 0 30px 0;
    cursor: pointer; 
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
}

.loading_process {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all ease-in 0.3s;
}
.menuHeader{
    width: 100%;
    height: 40px;
    background: #fff;
    border-bottom: 1px solid #cecece;
    box-shadow: 0 1px 2px -2px #333;
    z-index: 5;
}
.menuHeader ul{
    list-style: none;
    margin: 0;
    padding: 0;
}

.menuHeader ul > li > span ~ ul {
    min-height: calc(100vh - 100px);
} 
.menuHeader > ul{
    display: flex;
    position: relative;
    justify-content: space-evenly;
    align-items: center;
}
.menuHeader > ul > li > span{
    display: block;
    line-height: 40px;
    cursor: pointer;
    padding: 0 20px;
    font-size: 14px;
    font-weight: 500;
}
.menuHeader > ul > li > span:hover{
    color: #2874f0;
}

.menuHeader > ul > li > span:hover > i{
    color: #2874f0 !important;
}

.menuHeader > ul > li > ul{
    position: absolute;
    background: #fff;
    top: calc(100% - 2px);
    left: 60px;
    right: 60px;
    display: none;
    border: 1px solid #cecece;
    z-index: 10;
    padding: 0 10px;
}
.menuHeader > ul > li:hover ul{
    display: block;
}
.menuHeader > ul > li > ul > li{
    margin: 0 20px;
    min-width: 160px;
}
.menuHeader > ul > li > ul > li{
    float: left;
}
/* .menuHeader > ul > li > ul > li:nth-child(2n + 1) {
    background-color: red;
} */

.menuHeader > ul > li > ul > li > a{
    font-weight: bold;
    display: block;
}
.menuHeader > ul > li > ul > li a{
    padding: 3px 0;
    display: block;
    font-size: 12px;
    text-decoration: none;
    color: #707070;
}
.slide_banner {
    padding: 8px 0;
    overflow: hidden;
    position: relative;
}


.supper_deal {
    margin-left: 8px;
    margin-bottom: 8px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%);
}

.super_deal_img {
    background-color: #fff;
    padding: 8px;
    height: 354px;
}

.super_deal_img img {
    max-width: 100%;
    max-height: 100%;
}

.card_product_container {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%);
    margin-bottom: 8px;
    flex: 82% 1;
}

.card_product_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid #f0f0f0;
}

.card_product_header_name {
    font-size: 22px;
    font-weight: 500;
}

.card_product_view_all {
    background: #2874f0;
    color: #fff;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
}


.card_product_content {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.card_product_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 8px;
}

.card_product_img {
    height: 150px;
    width: 150px;
    position: relative;
}

.card_product_img img {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
}

.card_product_name {
    font-size: 13px;
    font-weight: 500;
    padding: 10px 0;
}

.card_product_sell {
    font-size: 13px;
    color: #388e3c;
}

.footer_introduce {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
}

.footer_intro_container {
    margin: 20px 20px 10px;
}

.footer_intro_header {
    font-size: 13px;
    color: #798587;
}

.footer_intro_content {
    font-size: 10px;
}
/* ---------------------------- */

.footer_container {
    display: flex;
    flex-direction: column;
    background-color: #172337;
    color: #fff;
}

.footer_info {
    display: flex;
    justify-content: space-evenly;
    padding: 40px 0;
}

.footer_header {
    color: #798587;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.footer_list {
    list-style: none;
}

.footer_item {
    font-size: 12px;
    margin-bottom: 8px;
}

.footer_help {
    border-left: 1px solid #454d5e;
    padding: 0 25px;
}


.footer_policy {
    border-left: 1px solid #454d5e;
    padding: 0 25px;
}


.footer_social {
    border-left: 1px solid #454d5e;
    padding: 0 25px;
}


.footer_address {
    border-left: 1px solid #454d5e;
    padding: 0 25px;
}


.footer_payment {
    border-top: 1px solid #454d5e;
    padding: 30px 20px;
}

.footer_payment_list {
    display: flex;
    font-size: 13px;
    align-items: center;
    justify-content: space-between;
    list-style: none;
}

.footer_icon {
    color: #ffbb00;
    margin-right: 6px;
}


.sub_header_container {
    background-color: #fff;
    min-height: 112px;
    position: relative;
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 16%);
    z-index: 5;
}


.sub_header_list {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.sub_header_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    padding: 12px 8px;
}

.sub_header_item:hover {
    color: #2874f0;
    cursor: pointer;
}

.sub_header_img {
    max-height: 64px;
    max-width: 64px;
}

.sub_header_img img{
    width: 100%;
    height: 100%;
}

.sub_header_name {
    
}

.productpage_container {
    background-color: #eff1f4;
}

.productpage_content {
    display: flex;
    padding: 8px 2px 20px 5px;
    justify-content: space-around;
    overflow: hidden;
}

.productpage_sidebar {
    flex-basis: 21.4%;
    flex-flow: column;
    max-width: 280px;
    background-color: #ffffff;
    box-shadow: 0 1px 8px 0 rgb(0 0 0 / 6%);
}

.productpage_product {
    flex-basis: 77%;
    background-color: #ffffff;
    box-shadow: 0 1px 8px 0 rgb(0 0 0 / 6%);
}

.productpage_sidebar-header-filter {
    font-size: 18px;
    font-weight: 500;
    padding: 13px;
    border-bottom: 1px solid #f0f0f0;
}

.productpage_sidebar-header {
    font-size: 13px;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: 500;
}

.productpage_sidebar-link {
    border-bottom: 1px solid #f0f0f0;
    padding: 13px;
}

.productpage_sidebar-link-category {
    font-size: 13px;
    color: #878787;
}

.productpage_sidebar-link-product {
    font-size: 13px;
    font-weight: 500;
    margin-left: 15px;
    margin-top: 10px;
}

.productpage_sidebar input[type="checkbox"] {
    margin-right: 10px;
}

.productpage_sidebar span {
    font-size: 13px;
    min-width: 10px;
    flex-shrink: 33%;
}

.productpage-checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.productpage-checkbox label {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* --------------- */
.productpage_product-filter {

}

.productpage_product-filter-head {
    border-bottom: 1px solid #f0f0f0;
    padding: 12px;
}

.productpage_product-content {
    display: flex;
    border-bottom: 1px solid #f0f0f0;
}

.productpage_product-link {
    font-size: 12px;
    color: #878787;
}

.productpage_product-recommend {
    color: #2874f0;
    font-size: 12px;
    margin: 14px 0;
    cursor: pointer;
}

.productpage_pro_icon {
    margin-right: 10px;
}
.productpage_product-link span {
    margin-right: 10px;
    max-width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: middle;
}

.productpage_product-link span:hover {
    color: #2874f0;
    cursor: pointer;
    display: inline;
}

.productpage_product-recommend span:not(:last-child):after{
    content: "";
    margin: 0 8px;
    border-right: 1px solid #878787;
}

.productpage_product-category {
    margin: 16px 0 10px;
}

.productpage_product-category .productpage_product-category-name {
    font-size: 16px;
    font-weight: 500;
    margin-right: 10px;
}
.productpage_product-category .productpage_product-category-quantity {
    color: #878787;
    font-size: 11px;
}

.productpage_product-filter .productpage_product-filter-sort {
    padding: 4px 0;
    font-size: 13px;
    cursor: pointer;
}

.productpage_product-filter .productpage_product-filter-sort:first-child {
    font-weight: 500;
}

.productpage_product-filter .productpage_product-filter-sort.active {
    border-bottom: 2px solid #2874f0;
}

.productpage_product-filter .productpage_product-filter-sort:not(:first-child, :last-child) {
    margin: 0 10px;
}


/* ------------ */

.productpage_product-content {
    padding: 24px 0 30px 24px;
}

.productpage_product-content-img {
    height: 200px;
    width: 200px;
    cursor: pointer;
    position: relative;
    display: flex;
    margin: auto 0;
}

.productpage_product-content-img img {
    max-width: 100%;
    max-height: 100%;
    display: flex;
    margin: auto;
}

.productpage_product-content-about {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    padding-left: 25px;
}
.productpage_product-content-header h2{
    color: #212121;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
}

.productpage_product-content-header h2:hover {
    color: #2874f0;
}

.productpage_product-content-header span {
    font-size: 14px;
}

.productpage_product-content-header i {
    margin-right: 5px;
    margin-left: 2px;
    font-size: 8px;
}


.productpage_product-content-des-list {
    font-size: 13px;
    list-style: none;
    margin-left: 12px;
}

.productpage_product-content-des-item::before {
    content: "\2022";
    color: #878787;
    font-weight: bold;
    display: inline-block; 
    width: 1em;
    margin-left: -1em;
}

.productpage_product-content-price {
    padding-left: 40px;
}

.productpage_product-content-price-root {
    font-size: 25px;
    font-weight: 500;
}

.productpage_product-content-price-discount span:first-child {
    font-size: 14px; 
    color: #878787;
    text-decoration: line-through;
    margin-right: 10px;
}

.productpage_product-content-price-discount span:last-child {
    font-size: 13px;
    color: #388e3c;
}

.productpage_product-content-price-delivery {
    font-size: 12px;
}

.productpage_product-content-price-offer {
    font-weight: 700;
    color: #26a541;
    font-size: 14px;
}



/* ------- */

.paginationBttns {
    width: 80%;
    height: 40px;
    list-style: none;
    display: flex;
    justify-content: center;
  }
  
  .paginationBttns a {
    padding: 10px;
    margin: 8px;
    border-radius: 5px;
    border: 1px solid #2b2eff;
    color: #2b2eff;
    cursor: pointer;
  }
  
  .paginationBttns a:hover {
    color: white;
    background-color: #2b2eff;
  }
  
  .paginationActive a {
    color: white;
    background-color: #2b2eff;
  }
  
  .paginationDisabled a {
    color: grey;
    background-color: grey;
  }



.clothing_list {
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

.clothing_item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 25%;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 40px;
}

.clothing_item:hover {
    -webkit-transform: scale(102%);
            transform: scale(102%);
    transition: -webkit-transform ease-in 0.2s;
    transition: transform ease-in 0.2s;
    transition: transform ease-in 0.2s, -webkit-transform ease-in 0.2s;
    box-shadow: 0 1px 8px 0 rgb(0 0 0 / 10%);
}

.clothing_img {
    height: 250px;
}

.clothing_img img {
    max-width: 100%;
    max-height: 100%;
}

.clothing_inf {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.clothing_name {
    text-align: center;
}

.clothing_name span{
    width: 30%;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}

.clothing_inf img {
    max-height: 18px;
}

.clothing_price {

}

.clothing_sell {
    font-size: 14px;
    font-weight: 500;
}

.clothing_root {
    font-size: 14px;
    text-decoration: line-through;
    color: #878787;
    margin: 0 4px;
}

.clothing_off {
    font-size: 12px;
    color: #388e3c;
}

.clothing_size span:first-child{
    font-weight: 500;
    font-size: 13px;
    padding-right: 4px;
}

.paginationBtn {
  margin: 20px 0;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginationBtn li {
  margin: 0 20px;
  cursor: pointer;
  font-weight: 500;
}

.previousBtn, .nextBtn{
  text-transform: uppercase;
  color: #2874f0;
  font-size: 14px;
  margin: 0 40px;
  font-weight: 500;
}

.paginationActive {
  display: inline-block;
  padding: 0 8px;
  cursor: pointer;
  line-height: 32px;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background: #2874f0;
  color: #fff;
  text-align: center;
  font-size: 13px;
}

.paginationActive a {
  background-color: transparent !important;
}
.product_brand_container {
    background-color: #f1f3f6;
    padding: 8px;
    display: flex;
    flex-direction: column;
}

.product_brand_content {
    background-color: #fff;
}

.product_brand_list {
    display: flex;
    height: 192px;
}

.product_brand_item {
    width: 204px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.product_brand_item:hover {
    cursor: pointer;
    color: #2874f0;
    -webkit-transform: scale(104%);
            transform: scale(104%);
    transition: -webkit-transform ease-in 0.2s;
    transition: transform ease-in 0.2s;
    transition: transform ease-in 0.2s, -webkit-transform ease-in 0.2s;
}

.product_brand_img {
    max-width: 156px;
    margin-bottom: 16px;
    margin-top: 24px;
}

.product_brand_img img {
    max-width: 150px;
    height: auto;
}

.product_brand_buy {
    font-weight: 500;
    font-size: 14px;
}

.product_banner {
    width: 100%;
    height: 280px;
    position: relative;
}

.product_banner img {
    position: absolute;
    max-width: 100%;
    height: 280px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.productpage_container {
    background-color: #eff1f4;
}

.price_range {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 10px 0;
}

.price_range input {
    width: 60px;
    text-align: center;
}

.productpage_content {
    display: flex;
    padding: 8px 2px 20px 5px;
    justify-content: space-around;
}

.productpage_sidebar {
    flex-basis: 21.4%;
    flex-flow: column;
    max-width: 280px;
    background-color: #ffffff;
    box-shadow: 0 1px 8px 0 rgb(0 0 0 / 6%);
    height: 100%;
}

.productpage_product {
    flex-basis: 77%;
    background-color: #ffffff;
    box-shadow: 0 1px 8px 0 rgb(0 0 0 / 6%);
}

.productpage_sidebar-header-filter {
    font-size: 18px;
    font-weight: 500;
    padding: 13px;
    border-bottom: 1px solid #f0f0f0;
}

.productpage_sidebar-header {
    font-size: 13px;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: 500;
}

.productpage_sidebar-link {
    border-bottom: 1px solid #f0f0f0;
    padding: 28px;
}

.productpage_sidebar-link-category {
    font-size: 13px;
    color: #878787;
}

.productpage_sidebar-link-product {
    font-size: 13px;
    font-weight: 500;
    margin-left: 15px;
    margin-top: 10px;
}

.productpage_sidebar input[type="checkbox"] {
    margin-right: 10px;
}

.productpage_sidebar span {
    font-size: 13px;
    min-width: 10px;
    flex-shrink: 33%;
}

.productpage-checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.productpage-checkbox label {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* --------------- */
.productpage_product-filter {

}

.productpage_product-filter-head {
    border-bottom: 1px solid #f0f0f0;
    padding: 12px;
}

.productpage_product-content {
    display: flex;
    border-bottom: 1px solid #f0f0f0;
}

.productpage_product-link {
    font-size: 12px;
    color: #878787;
}

.productpage_product-recommend {
    color: #2874f0;
    font-size: 12px;
    margin: 14px 0;
    cursor: pointer;
}

.productpage_pro_icon {
    margin-right: 10px;
}
.productpage_product-link span {
    margin-right: 10px;
    max-width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: middle;
}

.productpage_product-link span:hover {
    color: #2874f0;
    cursor: pointer;
    display: inline;
}

.productpage_product-recommend span:not(:last-child):after{
    content: "";
    margin: 0 8px;
    border-right: 1px solid #878787;
}

.productpage_product-category {
    margin: 16px 0 10px;
}

.productpage_product-category .productpage_product-category-name {
    font-size: 16px;
    font-weight: 500;
    margin-right: 10px;
}
.productpage_product-category .productpage_product-category-quantity {
    color: #878787;
    font-size: 11px;
}

.productpage_product-filter .productpage_product-filter-sort {
    padding: 4px 0;
    font-size: 13px;
    cursor: pointer;
}

.productpage_product-filter .productpage_product-filter-sort:first-child {
    font-weight: 500;
}

.productpage_product-filter .productpage_product-filter-sort.active {
    border-bottom: 2px solid #2874f0;
}

.productpage_product-filter .productpage_product-filter-sort:not(:first-child, :last-child) {
    margin: 0 10px;
}


/* ------------ */

.productpage_product-content {
    padding: 24px 0 30px 24px;
}

.productpage_product-content-img {
    height: 200px;
    width: 200px;
    cursor: pointer;
    position: relative;
    display: flex;
    margin: auto 0;
}

.productpage_product-content-img img {
    max-width: 100%;
    max-height: 100%;
    display: flex;
    margin: auto;
}

.productpage_product-content-about {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    padding-left: 25px;
}
.productpage_product-content-header h2{
    color: #212121;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
}

.productpage_product-content-header h2:hover {
    color: #2874f0;
}

.productpage_product-content-header span {
    font-size: 14px;
}

.productpage_product-content-header i {
    margin-right: 5px;
    margin-left: 2px;
    font-size: 8px;
}


.productpage_product-content-des-list {
    font-size: 13px;
    list-style: none;
    margin-left: 12px;
}

.productpage_product-content-des-item::before {
    content: "\2022";
    color: #878787;
    font-weight: bold;
    display: inline-block; 
    width: 1em;
    margin-left: -1em;
}

.productpage_product-content-price {
    padding-left: 40px;
}

.productpage_product-content-price-root {
    font-size: 25px;
    font-weight: 500;
}

.productpage_product-content-price-discount span:first-child {
    font-size: 14px; 
    color: #878787;
    text-decoration: line-through;
    margin-right: 10px;
}

.productpage_product-content-price-discount span:last-child {
    font-size: 13px;
    color: #388e3c;
}

.productpage_product-content-price-delivery {
    font-size: 12px;
}

.productpage_product-content-price-offer {
    font-weight: 700;
    color: #26a541;
    font-size: 14px;
}



/* ------- */

.paginationBttns {
    width: 80%;
    height: 40px;
    list-style: none;
    display: flex;
    justify-content: center;
  }
  
  .paginationBttns a {
    padding: 10px;
    margin: 8px;
    border-radius: 5px;
    border: 1px solid #2b2eff;
    color: #2b2eff;
    cursor: pointer;
  }
  
  .paginationBttns a:hover {
    color: white;
    background-color: #2b2eff;
  }
  
  .paginationActive a {
    color: white;
    background-color: #2b2eff;
  }
  
  .paginationDisabled a {
    color: grey;
    background-color: grey;
  }



.clothing_list {
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

.clothing_item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 25%;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 40px;
}

.clothing_item:hover {
    -webkit-transform: scale(102%);
            transform: scale(102%);
    transition: -webkit-transform ease-in 0.2s;
    transition: transform ease-in 0.2s;
    transition: transform ease-in 0.2s, -webkit-transform ease-in 0.2s;
    box-shadow: 0 1px 8px 0 rgb(0 0 0 / 10%);
}

.clothing_img {
    height: 250px;
}

.clothing_img img {
    max-width: 100%;
    max-height: 100%;
}

.clothing_inf {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.clothing_name {
    text-align: center;
}

.clothing_name span{
    width: 30%;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}

.clothing_inf img {
    max-height: 18px;
}

.clothing_price {

}

.clothing_sell {
    font-size: 14px;
    font-weight: 500;
}

.clothing_root {
    font-size: 14px;
    text-decoration: line-through;
    color: #878787;
    padding: 0 10px;
}

.clothing_off {
    font-size: 12px;
    color: #388e3c;
}

.clothing_size span:first-child{
    font-weight: 500;
    font-size: 13px;
    padding-right: 4px;
}

.product-container {
    background-color: #f1f3f6;
    padding: 8px;
}

.product-container:last-child {
    padding-bottom: 80px;
}

.cart-product-container {
    width: 100%;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%);
}


.cart-header {
    display: flex;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    padding: 22px;
    border-bottom: 1px solid rgba(0,0,0,.1);
    height: 64px;
    align-items: center;
    justify-content: space-between;
}

.cart-header h2 {
    font-size: 20px;
    font-weight: 500;
}

.cart-header span {
    background-color: #2874f0;
    color: #fff;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
    font-size: 13px;
    padding: 10px 20px;
    font-weight: 500;
    cursor: pointer;
}
.cart-content {
    display: flex;
    justify-content: space-around;
}


.cart-product-item {
    width: 220px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.product-img {
    width: 150px;
    height: 150px;
    position: relative;
    margin: 0 auto;
}

.product-img img {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
}

.product-name {
    font-size: 14px;
    margin: 14px 0;
}
.product-start {
    font-size: 12px;
    display: flex;
}

.product-start span:first-child {
    background-color: #388e3c;
    padding: 0px 2px;
    border-radius: 2px;
    line-height: 17px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.product-start span:last-child {
    font-size: 14px;
}

.product-price {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.product-price-root {
    font-size: 16px;
}
.product-price-sell {
    font-size: 14px;
    color: #878787;
    margin: 0 8px;
    text-decoration: line-through;
}
.product-price-sell-off {
    font-size: 13px;
    color: #388e3c;
}
.scroll_to_top {
    position: fixed;
    bottom: 12px;
}

.login_page_form {
    position: relative;
    top: 46px;
}

.login_page_form > span i {
    font-size: 30px;
    color: #fff;
}

.login_page_form > span {
    position: absolute;
    cursor: pointer;
    top: -6;
    right: -24px;
}

.login_page_container {
    width: 100%;
    display: flex;
    color: #212121;
    margin: auto;
    border-radius: 2px;
    height: 528px;
    max-width: 750px;
    min-width: 650px;
    border: 1px solid #878787;
}
.login_page_image {
    background-image: url(https://res.cloudinary.com/de4aiajqg/image/upload/v1652710961/avatar/login_img_c4a81e_wg7kqo.png);
    background-position: center 85%;
    background-repeat: no-repeat;
    background-color: #2874f0;
    color: #fff;
    padding: 40px 33px;
    font-size: 15px;
    width: 40%;
    display: flex;
    flex-direction: column;
}

.login_page_image span:first-child {
    font-size: 24px;
    margin-bottom: 20px;
}

.login_page_content {
    padding: 56px 35px 16px;
    width: 60%;
    
}

.input_email,
.input_password
{
    position: relative;
    margin-bottom: 26px;
    font-size: 16px;
    color: #212121;
    pointer-events: auto;
    width: 100%;
}

.input_email input,
.input_password input
{
    padding: 8px 10px 10px 0;
    width: 100%;
    color: #000;
    font-size: 14px;
    background: #fff;
    border: none;
    border-bottom: 1px solid #e0e0e0;
    outline: none;
}

.input_password input {
    position: relative;
}

.input_password span {
    position: absolute;
    right: 0;
    top: 20px;
    font-size: 14px;
    color: #2874f0;
    font-weight: 500;
    cursor: pointer;
}

.login_page_policy {
    font-size: 12px;
}

.login_page_policy span span {
    color: #2874f0;
}

.login_page_btn {
    background-color: #fb641b;
    color: #fff;
    text-align: center;
    font-size: 14px;
    padding: 14px 20px;
    margin: 10px 0;
    cursor: pointer;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
}

.login_page_OTP {
    background-color: #fff;
    color: #2874f0;
    text-align: center;
    font-size: 14px;
    padding: 14px 20px;
    margin: 10px 0 30px 0;
    cursor: pointer; 
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
}

.login_page_or {
    color: #878787;
    text-align: center;
}

.login_page_social {
    display: flex;
    color: #2874f0;
    align-items: center;
    justify-content: space-around;
}

.login_page_social_item {
    cursor: pointer;
    padding: 10px 16px;
    border-radius: 100%;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);

}

.login_page_social_item:nth-child(2) {
    padding: 10px 18px;
}

.create_account {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #2874f0;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 10px;
    cursor: pointer;
}
.activation_error {
    background-color: red;
    max-width: 400px;
    padding: 30px;
    display: flex;
    margin: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 38%);
}

.activation_success {
    background-color: green;
    max-width: 400px;
    padding: 30px;
    display: flex;
    margin: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 38%);
}

.error_name, .success_name {
    color: #fff;
    padding-bottom: 20px;
    font-size: 18px;
}

.error_icon, .success_icon {
    color: #fff;
    font-size: 30px;
}

.product_detail_container {
    position: relative;
}

.product_detail_content {
    display: flex;
    padding: 16px;
}

.product_detail_prod {
    position: -webkit-sticky;
    position: sticky;
    top: 64px;
    bottom: 0;
    width: 35.66%;
    height: 500px;
    z-index: 2;
    display: flex;
    flex-direction: column;
}

/* .product_detail_img {
    border: 1px solid #f0f0f0;
} */

.product_detail_img {
    display: flex;
}

.product_img_list {
    list-style: none;
}

.product_img_item {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 5px;
    width: 64px;
    height: 64px;
    border-color: #f0f0f0 !important;
    border-bottom: 1px solid #f0f0f0;
    border-left: 1px solid #f0f0f0;
}

.product_img_item:hover {
    border: 2px solid #2874f0 !important;
    cursor: pointer;
}

.product_img_item:first-child {
    border-top: 1px solid #f0f0f0;
}


.product_img_item img {
    max-width: 100%;
    max-height: 100%;
    text-align: center;
    padding: 2px;
}

.product_img_primary {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1;
    position: relative;
    border: 1px solid #f0f0f0;
    min-height: 436px;
}
.product_img_primary img {
    max-height: 416px;
    max-width: 324px;
    margin: 0 auto;
    cursor: crosshair;
}

.product_add_order {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 12px;
}

.product_add_to_cart {
    padding: 16px 8px;
    border-radius: 2px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
    border: none;
    width: 100%;
    margin-left: 63px;
    margin-right: 10px;
    background-color: #ff9f00;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
}
.product_add_to_cart span {
    margin-left: 10px;
}

.product_order span {
    margin-left: 10px;
}

.product_order {
    padding: 16px 8px;
    border-radius: 2px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
    border: none;
    width: 100%;
    color: #fff;
    font-size: 15px;
    background-color: #fb641b;
    font-weight: 500;
    cursor: pointer;
}

/* --------------------------- */

.product_detail_description {
    width: 58.34%;
    padding-left: 20px;
}

.product_details_sub_nav {
    font-size: 11px;
    color: #878787;
}

.product_details_sub_nav span {
    margin-right: 8px;
}

.product_detail_name span {
    display: inline-block;
    font-size: 16px;
    color: #212121;
    margin-top: 10px;
    margin-bottom: 10px;
}

.product_detail_rating {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.product_detail_rating span:first-child {
    font-size: 13px;
    color: #fff;
    background-color: #388e3c;
    border-radius: 4px;
    padding: 2px;
    text-align: center;
    line-height: 21px;
    margin-right: 10px;
}

.product_detail_price {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.product_details_sell {
    font-size: 26px;
    color: #212121;
}

.product_details_root {
    text-decoration: line-through;
    font-size: 16px;
    color: #878787;
    margin: 0 10px;
}

.product_details_off {
    color: #388e3c;
    font-size: 16px;
}

.product_detail_offer > span {
    display: inline-block;
    color: #212121;
    font-size: 15px;
    font-weight: 500;
    margin: 14px 0 8px 0;
}

.product_detail_offer_list {
    list-style: none;
    font-size: 13px;
}

.product_detail_offer_item i {
    color: #388e3c;
    margin-right: 8px;
    font-size: 16px;
}

.product_detail_offer_item i + span {
    color: #212121;
    font-weight: bold;
}

.product_detail_offer_item {
    margin-bottom: 10px;
}

.product_detail_highlight {
    display: flex;
    margin-top: 60px;
    margin-bottom: 40px;
}

.highlight_item::before {
    content: "\2022";
    color: #878787;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}

.product_detail_highlight span {
    font-size: 14px;
    color: #878787;
    flex-basis: 20%;
}

.highlight_list {
    list-style: none;
}

.highlight_item {
    font-size: 13px;
}

.product_detail_des {
    display: flex;
    margin-bottom: 40px;
}

.product_detail_des span{
    font-size: 14px;
    color: #878787;
    min-width: 18%;
}

.product_detail_des_para {
    font-size: 13px;
}

.product_detail_buy_together {
    border: 1px solid #f0f0f0;
    padding: 30px;
}

.product_detail_buy_together > span {
    display: inline-block;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
}

.buy_together_prod {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.buy_together_item {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    align-items: center;
}

.buy_together_img {
    width: 112px;
    height: 112px;
    position: relative;
    margin-bottom: 20px;
}

.buy_together_img img {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
}

.buy_together_name {
    font-size: 14px;
    width: 100%;
}

.buy_together_name span {
    display: inline-block;
    max-width: 100%;
    text-align: center;
}

.buy_together_rating {
}

.buy_together_sell {
    font-size: 15px;
    color: #212121;
    font-weight: 500;
}

.buy_together_root {
    text-decoration: line-through;
    font-size: 13px;
    color: #878787;
    padding: 0 6px;
}

.buy_together_off {
    color: #388e3c;
    font-size: 12px;
}

.buy_together_plus i{
    font-size: 26px;
    color: #878787;
}


.rating_review {
    display: flex;
    flex-direction: column;
    border: 1px solid #f0f0f0;
    margin-top: 20px;
    padding: 30px;
}

.rating_review > span {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
}

.rating_review_content {
    display: flex;
    align-items: center;
}

.rating_review_avg {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 20%;
    margin-right: 20px;
}
.rating_review_avg span span {
    font-size: 26px;
    margin-right: 4px;
}

.rating_review_avg span:not(:first-child) {
    font-size: 14px;
    color: #878787;
}

.rating_review_avg span:first-child i {
    font-size: 16px;
}

.rating_review_star {
    flex-basis: 40%;
}

.rating_list {
    list-style: none;
}

.rating_item span span {
    display: inline-block;
    min-width: 10px;
    margin-right: 2px;
    font-size: 11px;
}
.rating_item span i {
    font-size: 8px;
}

.rating_item {
    position: relative;
}

.rating_item .rating_item_start {
    display: inline-block;
    height: 5px;
    border-radius: 100px;
    background-color: #f0f0f0;
    width: 100px;
    margin: 0 10px;
}

.rating_item .rating_item_start span.start{
    display: block;
    border-radius: 100px;
    height: 5px;
    z-index: 2;
}

.start_100 {
    width: 100%;
    background-color: #388e3c;
}

.start_80_100 {
    width: 80%;
    background-color: #388e3c;
}

.start_50_80 {
    width: 50%;
    background-color: #388e3c;
}

.start_30_50 {
    width: 30%;
    background-color: #ff9f00;
}

.start_0_30 {
    width: 10%;
    background-color: #ff6161;
}


.rating_item span:nth-child(3) {
    font-size: 12px;
    color: #878787;
}

.rating_detail {
    display: flex;
    flex: 50% 1;
}
.rating_detail_item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rating_detaik_item_per {
    margin-left: 20px;
}


svg {
    width: 100%;
    stroke-linecap: round;
}

svg path:first-child {
    stroke: #f0f0f0;
}

svg path:nth-child(2) {
    stroke: #388e3c;
}

.text {
    fill: #212121;
    font-weight: 500;
}

.interested_product {
    padding: 40px;
    overflow: hidden;
}

.interested_prod_header p {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 40px;
}
.interested_prod_content {
    display: flex;
    overflow-x: hidden;
    justify-content: space-around;
}

.interested_prod_item:not(:last-child) {
    margin-right: 20px;
}

.interested_prod_item {
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    width: 400px;
    box-shadow: 0 1px 8px 0 rgb(0 0 0 / 16%);
}

.interested_prod_img {
    width: 140px;
    position: relative;
    cursor: pointer;
}

.interested_prod_img img {
    max-width: 100%;
    max-height: 100%;
    
}

.interested_prod_shopnow {
    padding-left: 20px;
}

.interested_prod_shopnow_name {
    font-size: 20px;
    font-weight: 500;
}

.interested_prod_shopnow_sell {
    color: #878787;
    padding: 4px 0 6px 0;
}

.interested_prod_shopnow_buy {
    margin-top: 16px;
    color: #fff;
    background: #2874f0;
    border-radius: 2px;
    border: none;
    padding: 8px 16px;
    text-align: center;
}

.empty_content {
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
    justify-content: center;
}

.empty_msg {
    font-size: 16px;
    color: #212121;
    margin-top: 30px;
}

.empty_img {
    height: 162px;
    margin: auto;
    display: flex;
    align-items: center;
}

.empty_img img {
    height: 100%;
}

.empty_login {
    background: #fb641b;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
    border: none;
    color: #fff;
    font-size: 14px;
    margin-top: 20px;
    font-weight: 400;
    padding: 12px 72px;
    cursor: pointer;
}


.cartpage_container {
    background-color: #f1f3f6;
    padding: 20px 40px;
    overflow: visible;
}

.cartpage_content {
    display: flex;
    justify-content: space-between;
    padding: 8px;
}

.cartpage_product {
    background-color: #fff;
    flex: 2 1;
    border-radius: 2px;
}

.cartpage_number {
    color: #212121;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    height: 84px;
    border-bottom: 1px solid #f0f0f0;
    padding-left: 20px;
}

.cartpage_number span {
    line-height: 84px;
}

.cartpage_item:last-child {
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 20%);
}

.cartpage_item {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.cartpage_item:not(:last-child) {
    border-bottom: 1px solid #f0f0f0;
}

.cartpage_item:last-child {
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 20%) !important;
}

.cartpage_item_content {
    display: flex;
}

.cartpage_img {
    height: 112px;
    width: 112px;
    padding: 10px;
    flex: 1 1;
    margin: 0px 20px 20px 30px;
    position: relative;
}

.cartpage_img img {
    max-width: 100%;
    height: 112px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.cartpage_item_info {
    flex: 4 1;
}

.cartpage_item_name {
    font-size: 15px;
    cursor: pointer;
}

.cartpage_item_name:hover {
    color: #2874f0;
}

.cartpage_item_seller {
    font-size: 13px;
    color: #878787;
    padding-top: 10px;
    margin-bottom: 10px;
}

.cartpage_item_seller span {
    display: inline-block;
    margin-right: 10px;
}

.cartpage_item_price span:first-child{
    color: #212121;
    font-size: 17px;
    font-weight: 500;
}

.cartpage_item_price span:nth-child(2){
    color: #212121;
    font-size: 14px;
    padding: 0 10px;
    color: #878787;
    text-decoration: line-through;
}

.cartpage_item_price span:last-child{
    color: #388e3c;
    font-size: 14px;
}

.item_package {
    font-size: 13px;
    margin-bottom: 10px;
}

.cartpage_item_delivery {
    font-size: 12px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    margin-left: 140px;
}

.cartpage_item_delivery span:last-child {
    color: #878787;
}


.cartpage_item_quantity .dec, .cartpage_item_quantity .inc {
    width: 28px;
    height: 28px;
    background: linear-gradient(#fff,#f9f9f9);
    display: inline-block;
    border: 1px solid #c2c2c2;
    cursor: pointer;
    font-size: 16px;
    border-radius: 50%;
    padding-top: 1px;
    line-height: 1;
}

.cartpage_item_buy {
    display: flex;
}

.cartpage_item_save {
    padding: 0 20px;
    font-weight: 500;
}

.cartpage_item_save span {
    cursor: pointer;
}


.cartpage_item_remove {
    font-weight: 500;
}

.cartpage_item_remove span {
    cursor: pointer;
}

.cartpage_item_quan {
    display: inline-block;
    padding: 3px 6px;
    height: 100%;
    width: 46px;
    height: 28px;
    border-radius: 2px;
    background-color: #fff;
    border: 1px solid #c2c2c2;
    margin: 0 5px;
    position: relative;
}

.cartpage_item_quantity input {
    border: none;
    width: 100%;
    outline: none;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.cartpage_item_quantity {
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.cartpage_item_delivery {
    flex: 3 1;
}

.cartpage_continue_btn {
    padding: 16px 22px;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #fff;
}

.cartpage_continue_btn button {
    background: #fb641b;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
    border: none;
    color: #fff;
    padding: 12px 80px;
    border-radius: 2px;
    font-size: 16px;
    font-weight: 500;
    position: relative;
    cursor: pointer;
}

.cartpage_price_details {
    background-color: #fff;
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 20%);
    border-radius: 2px;
    flex: 1 1;
    margin-left: 16px;
    position: -webkit-sticky;
    position: sticky;
    top: 64px;
    bottom: 0;
    z-index: 2;
    height: 100%;
}

.details_header {
    padding: 13px 24px;
    color: #878787;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid #f0f0f0;
}

.details_content {
    padding: 0 24px;
}

.details_price {
    font-size: 15px;
    color: #212121;
}

.details_price_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
}

.details_price_discount {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
    color: #388e3c;
}

.details_price_delivery {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
}

.details_price_fee {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
}

.details_price_total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
    border-top: 1px dashed#e0e0e0;
    border-bottom: 1px dashed#e0e0e0;
    padding: 20px 0;
    font-weight: 500;
    font-size: 17px;
}

.details_price_total_discount {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
    color: #388e3c;
    font-weight: 500;
}

.card {
  width: 100%;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 1px 0px !important;
}
.cardHeader {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px;
  border-bottom: 1px solid #cecece;
}

/* Material Modal */
.modalFixedBg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 12;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modalContainer {
  min-width: 400px;
  max-width: 90%;
  min-height: 200px;
  max-height: 90vh;
  background: #fff;
  border-radius: 4px;
  margin: 0 auto;
  overflow-x: hidden;
}
.modalClose {
  position: absolute;
  color: #fff;
  right: 15px;
  font-size: 20px;
}

/* Material Input */
.materialInput {
  width: 100%;
  min-width: 300px;
  height: 30px;
  border-bottom: 2px solid #2874f0;
  position: relative;
  /* background-color: red; */
  margin-top: 25px;
}
.materialInput input {
  width: 100%;
  flex: 1 1;
  outline: none;
  border: none;
  z-index: 1;
  background: transparent;
  line-height: 30px;
}
.materialInput .label {
  position: absolute;
  line-height: 30px;
  color: grey;
  z-index: 0;
  font-size: 12px;
  letter-spacing: 1px;
  transition: all 0.1s ease-in-out;
}
.materialInput .label.focus {
  top: -20px !important;
}

/* Material Button */
.materialButton {
  width: 100%;
  display: inline-block;
  padding: 15px;
  font-size: 15px;
  outline: none;
  background-color: #fb641b;
  color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  border: none;
  cursor: pointer;
  border-radius: 2px;
}
.materialButton:hover {
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.12);
}

/* Material dropdown */
.headerDropdownContainer {
  position: relative;
  display: inline-block;
}
.firstmenu {
  padding: 15px 20px;
  border-bottom: 1px solid #eee;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
}
.dropdown {
  right: 50%;
  position: absolute;
  width: 240px;
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
  z-index: 1;
  display: none;
}
.dropdownMenu {
  background: #fff;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
  background: #fff;
  border-radius: 2px;
}
.headerDropdownContainer:hover .dropdown {
  display: block;
}
.upArrowContainer {
  position: relative;
  width: 100%;
  height: 10px;
}
.upArrow {
  width: 0;
  height: 0;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #ffffff;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.headerDropdownMenu {
  margin: 0;
  padding: 0;
  background: #fff;
}
.headerDropdownMenu li {
  list-style: none;
}
.headerDropdownMenu li a {
  display: block;
  padding: 15px 20px;
  box-sizing: border-box;
  font-size: 14px;
  border-bottom: 1px solid #eee;
  color: #212121;
  text-decoration: none;
}
.headerDropdownMenu li a:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.anchorButton {
  background: transparent;
  border: none;
  letter-spacing: 1px;
}

.checkoutContainer{
    width: calc(100% - 400px);
}
.checkoutStep{
    box-sizing: border-box;
    background: #fff;
    box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
}
.checkoutHeader{
    width: 100%;
    box-sizing: border-box;
    padding: 10px 20px;
}
.checkoutHeader.active{
    background: #2874f0;
}
.checkoutHeader .stepNumber{
    display: inline-block;
    text-align: center;
    background: #eee;
    color: #2874f0;
    border-radius: 3px;
    font-size: 12px;
    box-sizing: border-box;
    padding: 3px 7px;
}
.checkoutHeader.active .stepNumber{
    background: #fff;
}
.stepTitle{
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    color: #878787;
    margin-left: 15px;
}
.checkoutHeader.active .stepTitle{
    color: #fff;
}
.loggedInId{
    font-size: 12px;
    margin: 0px 0 10px 60px;
}

.addressContainer{
    box-sizing: border-box;
    padding: 20px;
    background: #f5faff;
    display: flex;
    align-items: baseline;
}
.addressinfo{
    padding-left: 15px;
    box-sizing: border-box;
    width: 100%;
}
.addressDetail{
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 20px;
    font-weight: 600;
    font-family: 'Roboto';
    font-size: 12px;
    color: #333;
}
.addressType{
    display: inline-block;
    margin: 0 10px;
    text-transform: uppercase;
    background: #eee;
    font-weight: 500;
    padding: 1px 5px;
    font-size: 10px;
    border-radius: 2px;
    color: #777777;
}
.fullAddress{
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 18px;
}
.stepCompleted{
    padding: 0 0 20px 60px;
    font-size: 12px;
}
.order_page_container {
    background-color: #f1f3f6;
    padding: 20px 100px;
}

.order_page_content {
    display: flex;
    flex-direction: column;
}

.order_page_item {
    background-color: #fff;
    display: flex;
    padding: 20px 40px;
    margin-top: 10px;
    align-items: center;
}

.order_page_img {
    max-width: 56px;
    flex: 1 1;
}

.order_page_img img {
    width: 100%;
    height: 100%;
}

.order_page_name {
    display: flex;
    flex-direction: column;
    flex: 4 1;
    margin-left: 60px;
}

.order_page_name span:first-child {
    font-size: 15px;
    color: #212121;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}

.order_page_name span:last-child {
    font-size: 14px;
    color: #878787;
}

.order_page_delivery {
    flex: 3 1;
}

.order_page_price {
    display: flex;
    flex: 4 1;
}

.order_page_price span:first-child {
    font-size: 14px;
    color: #212121;
}

.order_page_price span:last-child i {
    color: #eded08;
    margin-left: 10px;
}

.order_page_price span:last-child {
    font-size: 14px;
}

.order_page_confirm {
    font-size: 13px;
    font-weight: 500;
    display: flex;
    align-items: center;
    text-transform: capitalize;
}

.order_page_confirm i {
    font-size: 8px;
    color: #388e3c;
    margin-right: 6px;
}

.order_page_noitice {
    font-size: 13px;
    color: #878787;
    margin: 10px 0;
}

.order_page_rate {
    font-size: 15px;
    display: flex;
    align-items: center;
    color: #2478f0;
}

.order_page_rate i {
    font-size: 13px;
    margin-right: 6px;
}
.delAdrContainer{
    display: flex;
    box-sizing: border-box;
}
.delTitle{
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
}
.delName{
    font-size: 12px;
    font-weight: 500;
}
.delAddress{
    font-size: 12px;
    margin: 10px 0;
}
.delPhoneNumber{
    font-size: 12px;
    font-weight: 500;
}
.delAdrDetails{
    padding: 20px;
    border-right: 1px solid #eee;
}
.delMoreActionContainer{
    padding: 20px;
}

.delItemImgContainer{
    width: 75px;
    height: 75px;
    overflow: hidden;
    text-align: center;
}
.delItemImgContainer > img{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.orderTrack{
    width: 400px;
    display: flex;
}
.orderStatus{
    width: 33.33%;
    height: 3px;
    background: #777;
    position: relative;
    font-size: 12px;
}
.orderStatus.active{
    background: #26A541;
}
.orderStatus:last-child{
    width: 0;
}
.orderStatus .point{
    width: 10px;
    height: 10px;
    position: absolute;
    border-radius: 5px;
    background-color: #777;
    top: -3px;
}
.orderStatus .point.active{
    background-color: #26A541;
}
.orderInfo{
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: -26px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.delItemName{
    font-size: 12px;
}

.orderInfo .status{
    color: #26A541;
    text-transform: capitalize;
    font-weight: bold;
}

.orderInfo .date{
    font-size: 10px;
}
.invoice-box {
    max-width: 800px;
    margin: auto;
    padding: 30px;
    border: 1px solid #eee;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    font-size: 16px;
    line-height: 24px;
    font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
    color: #555;
}

.invoice-box table {
    width: 100%;
    line-height: inherit;
    text-align: left;
    border-collapse: collapse;
}

.invoice-box table td {
    padding: 5px;
    vertical-align: top;
}

.invoice-box table tr td:nth-child(2) {
    text-align: right;
}

.invoice-box table tr.top table td {
    padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
    font-size: 45px;
    line-height: 45px;
    color: #333;
}

.invoice-box table tr.information table td {
    padding-bottom: 40px;
}

.invoice-box table tr.heading td {
    background: #eee;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
}

.invoice-box table tr.details td {
    padding-bottom: 20px;
}

.invoice-box table tr.item td {
    border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
    border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
    border-top: 2px solid #eee;
    font-weight: bold;
    padding-top: 20px;
}

.invoice-box table tr.item td:last-child {
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .invoice-box table tr.top table td {
        width: 124%;
        display: block;
        text-align: center;
    }

    .invoice-box table tr.information table td {
        width: 124%;
        display: block;
        text-align: center;
    }
}
.container_pro_search{
    background-color: #eff1f4;
    padding: 8px 8px 0 8px;
}

.content_prod_search {
    background-color: #fff;
}


.header_product_search {
 display: flex;
 flex-direction: column;
 border-bottom: 1px solid #f0f0f0;
 padding: 20px 14px 0 ;
}

.header_product_search h3 {
    font-size: 14px;
    color: #212121;
}

.header_sort_search {
    display: flex;
}

.filter-item-search:first-child {
    padding-left: 0;
}

.filter-item-search {
    font-size: 13px;
    padding: 10px 14px;
}

.filter-item-search:nth-child(2) {
    border-bottom: 2px solid #2874f0;
}

.body_prod_container {
    padding: 20px 30px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.body_prod_container .pro_name {
    margin-right: 10px;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: middle;
}

.body_pro_item {
    position: relative;
    padding: 10px;
    margin-bottom: 20px;
}

.body_pro_item:hover {
    cursor: pointer;
    box-shadow: 0 3px 16px 0 rgb(0 0 0 / 11%)
}


.body_pro_item p {
    font-size: 13px;
    color: #212121;
    padding-top: 8px
}

.body_pro_item > span {
    font-size: 11px;
    color: #878787;
}

.pro_img {
    height: 280px;
    width: 200px;
    position: relative;
}

.pro_img img {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
}

.prod_search_quantity {
    font-size: 14px;
    color: #878787;
}

.prod_search_start {
    display: flex;
    align-items: center;
}

.prod_search_price {
    display: flex;
    align-items: center;
    justify-content: first baseline;
}

.prod_search_price_sell {
    font-size: 13px;
    color: #212121;
}

.prod_search_price_root {
    font-size: 14px;
    text-decoration: line-through;
    color: #878787;
    margin: 0 10px;
}

.prod_search_price_offer {
    color: #388e3c;
    font-size: 13px;
}

.prod_search_delivery {
    font-size: 10px;
    color: #212121;
    font-weight: 400;
}











.forgot_pass_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.forgot_pass_container button {
    display: inline-block;
    padding: 10px;
    font-size: 14px;
    outline: none;
    background-color: #fb641b;
    color: #ffffff;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
    border: none;
    cursor: pointer;
    border-radius: 2px;
}

.forgot_pass_header {
    margin: 40px;
    font-size: 24px;
    font-weight: 700;
}

.forgot_pass_content input{
    padding: 10px 50px;
    outline: none;
    margin-bottom: 20px;
}

.forgot_message {
    margin-top: 20px;
    color: rgb(5, 224, 5);
}

.reset_container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 50px;
}

.reset_container span:first-child {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
}

.reset_container input {
    padding: 10px 80px;
    outline: none;
    margin-bottom: 20px;
    width: 30%
}


.reset_container p {
    margin-top: 20px;
    color: rgb(5, 224, 5);
}

.reset_container button {
    display: inline-block;
    padding: 10px;
    font-size: 14px;
    outline: none;
    background-color: #fb641b;
    color: #ffffff;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
    border: none;
    cursor: pointer;
    border-radius: 2px;
}
