.product_brand_container {
    background-color: #f1f3f6;
    padding: 8px;
    display: flex;
    flex-direction: column;
}

.product_brand_content {
    background-color: #fff;
}

.product_brand_list {
    display: flex;
    height: 192px;
}

.product_brand_item {
    width: 204px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.product_brand_item:hover {
    cursor: pointer;
    color: #2874f0;
    transform: scale(104%);
    transition: transform ease-in 0.2s;
}

.product_brand_img {
    max-width: 156px;
    margin-bottom: 16px;
    margin-top: 24px;
}

.product_brand_img img {
    max-width: 150px;
    height: auto;
}

.product_brand_buy {
    font-weight: 500;
    font-size: 14px;
}

.product_banner {
    width: 100%;
    height: 280px;
    position: relative;
}

.product_banner img {
    position: absolute;
    max-width: 100%;
    height: 280px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}