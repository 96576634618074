.sub_header_container {
    background-color: #fff;
    min-height: 112px;
    position: relative;
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 16%);
    z-index: 5;
}


.sub_header_list {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.sub_header_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    padding: 12px 8px;
}

.sub_header_item:hover {
    color: #2874f0;
    cursor: pointer;
}

.sub_header_img {
    max-height: 64px;
    max-width: 64px;
}

.sub_header_img img{
    width: 100%;
    height: 100%;
}

.sub_header_name {
    
}
