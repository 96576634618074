.product-container {
    background-color: #f1f3f6;
    padding: 8px;
}

.product-container:last-child {
    padding-bottom: 80px;
}

.cart-product-container {
    width: 100%;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%);
}


.cart-header {
    display: flex;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    padding: 22px;
    border-bottom: 1px solid rgba(0,0,0,.1);
    height: 64px;
    align-items: center;
    justify-content: space-between;
}

.cart-header h2 {
    font-size: 20px;
    font-weight: 500;
}

.cart-header span {
    background-color: #2874f0;
    color: #fff;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
    font-size: 13px;
    padding: 10px 20px;
    font-weight: 500;
    cursor: pointer;
}
.cart-content {
    display: flex;
    justify-content: space-around;
}


.cart-product-item {
    width: 220px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.product-img {
    width: 150px;
    height: 150px;
    position: relative;
    margin: 0 auto;
}

.product-img img {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
}

.product-name {
    font-size: 14px;
    margin: 14px 0;
}
.product-start {
    font-size: 12px;
    display: flex;
}

.product-start span:first-child {
    background-color: #388e3c;
    padding: 0px 2px;
    border-radius: 2px;
    line-height: 17px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.product-start span:last-child {
    font-size: 14px;
}

.product-price {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.product-price-root {
    font-size: 16px;
}
.product-price-sell {
    font-size: 14px;
    color: #878787;
    margin: 0 8px;
    text-decoration: line-through;
}
.product-price-sell-off {
    font-size: 13px;
    color: #388e3c;
}