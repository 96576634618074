.order_page_container {
    background-color: #f1f3f6;
    padding: 20px 100px;
}

.order_page_content {
    display: flex;
    flex-direction: column;
}

.order_page_item {
    background-color: #fff;
    display: flex;
    padding: 20px 40px;
    margin-top: 10px;
    align-items: center;
}

.order_page_img {
    max-width: 56px;
    flex: 1;
}

.order_page_img img {
    width: 100%;
    height: 100%;
}

.order_page_name {
    display: flex;
    flex-direction: column;
    flex: 4;
    margin-left: 60px;
}

.order_page_name span:first-child {
    font-size: 15px;
    color: #212121;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}

.order_page_name span:last-child {
    font-size: 14px;
    color: #878787;
}

.order_page_delivery {
    flex: 3;
}

.order_page_price {
    display: flex;
    flex: 4;
}

.order_page_price span:first-child {
    font-size: 14px;
    color: #212121;
}

.order_page_price span:last-child i {
    color: #eded08;
    margin-left: 10px;
}

.order_page_price span:last-child {
    font-size: 14px;
}

.order_page_confirm {
    font-size: 13px;
    font-weight: 500;
    display: flex;
    align-items: center;
    text-transform: capitalize;
}

.order_page_confirm i {
    font-size: 8px;
    color: #388e3c;
    margin-right: 6px;
}

.order_page_noitice {
    font-size: 13px;
    color: #878787;
    margin: 10px 0;
}

.order_page_rate {
    font-size: 15px;
    display: flex;
    align-items: center;
    color: #2478f0;
}

.order_page_rate i {
    font-size: 13px;
    margin-right: 6px;
}