.container_pro_search{
    background-color: #eff1f4;
    padding: 8px 8px 0 8px;
}

.content_prod_search {
    background-color: #fff;
}


.header_product_search {
 display: flex;
 flex-direction: column;
 border-bottom: 1px solid #f0f0f0;
 padding: 20px 14px 0 ;
}

.header_product_search h3 {
    font-size: 14px;
    color: #212121;
}

.header_sort_search {
    display: flex;
}

.filter-item-search:first-child {
    padding-left: 0;
}

.filter-item-search {
    font-size: 13px;
    padding: 10px 14px;
}

.filter-item-search:nth-child(2) {
    border-bottom: 2px solid #2874f0;
}

.body_prod_container {
    padding: 20px 30px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.body_prod_container .pro_name {
    margin-right: 10px;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: middle;
}

.body_pro_item {
    position: relative;
    padding: 10px;
    margin-bottom: 20px;
}

.body_pro_item:hover {
    cursor: pointer;
    box-shadow: 0 3px 16px 0 rgb(0 0 0 / 11%)
}


.body_pro_item p {
    font-size: 13px;
    color: #212121;
    padding-top: 8px
}

.body_pro_item > span {
    font-size: 11px;
    color: #878787;
}

.pro_img {
    height: 280px;
    width: 200px;
    position: relative;
}

.pro_img img {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
}

.prod_search_quantity {
    font-size: 14px;
    color: #878787;
}

.prod_search_start {
    display: flex;
    align-items: center;
}

.prod_search_price {
    display: flex;
    align-items: center;
    justify-content: first baseline;
}

.prod_search_price_sell {
    font-size: 13px;
    color: #212121;
}

.prod_search_price_root {
    font-size: 14px;
    text-decoration: line-through;
    color: #878787;
    margin: 0 10px;
}

.prod_search_price_offer {
    color: #388e3c;
    font-size: 13px;
}

.prod_search_delivery {
    font-size: 10px;
    color: #212121;
    font-weight: 400;
}










