.reset_container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 50px;
}

.reset_container span:first-child {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
}

.reset_container input {
    padding: 10px 80px;
    outline: none;
    margin-bottom: 20px;
    width: 30%
}


.reset_container p {
    margin-top: 20px;
    color: rgb(5, 224, 5);
}

.reset_container button {
    display: inline-block;
    padding: 10px;
    font-size: 14px;
    outline: none;
    background-color: #fb641b;
    color: #ffffff;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
    border: none;
    cursor: pointer;
    border-radius: 2px;
}