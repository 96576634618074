
.supper_deal {
    margin-left: 8px;
    margin-bottom: 8px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%);
}

.super_deal_img {
    background-color: #fff;
    padding: 8px;
    height: 354px;
}

.super_deal_img img {
    max-width: 100%;
    max-height: 100%;
}

.card_product_container {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%);
    margin-bottom: 8px;
    flex: 82%;
}

.card_product_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid #f0f0f0;
}

.card_product_header_name {
    font-size: 22px;
    font-weight: 500;
}

.card_product_view_all {
    background: #2874f0;
    color: #fff;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
}


.card_product_content {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.card_product_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 8px;
}

.card_product_img {
    height: 150px;
    width: 150px;
    position: relative;
}

.card_product_img img {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
}

.card_product_name {
    font-size: 13px;
    font-weight: 500;
    padding: 10px 0;
}

.card_product_sell {
    font-size: 13px;
    color: #388e3c;
}
