.menuHeader{
    width: 100%;
    height: 40px;
    background: #fff;
    border-bottom: 1px solid #cecece;
    box-shadow: 0 1px 2px -2px #333;
    z-index: 5;
}
.menuHeader ul{
    list-style: none;
    margin: 0;
    padding: 0;
}

.menuHeader ul > li > span ~ ul {
    min-height: calc(100vh - 100px);
} 
.menuHeader > ul{
    display: flex;
    position: relative;
    justify-content: space-evenly;
    align-items: center;
}
.menuHeader > ul > li > span{
    display: block;
    line-height: 40px;
    cursor: pointer;
    padding: 0 20px;
    font-size: 14px;
    font-weight: 500;
}
.menuHeader > ul > li > span:hover{
    color: #2874f0;
}

.menuHeader > ul > li > span:hover > i{
    color: #2874f0 !important;
}

.menuHeader > ul > li > ul{
    position: absolute;
    background: #fff;
    top: calc(100% - 2px);
    left: 60px;
    right: 60px;
    display: none;
    border: 1px solid #cecece;
    z-index: 10;
    padding: 0 10px;
}
.menuHeader > ul > li:hover ul{
    display: block;
}
.menuHeader > ul > li > ul > li{
    margin: 0 20px;
    min-width: 160px;
}
.menuHeader > ul > li > ul > li{
    float: left;
}
/* .menuHeader > ul > li > ul > li:nth-child(2n + 1) {
    background-color: red;
} */

.menuHeader > ul > li > ul > li > a{
    font-weight: bold;
    display: block;
}
.menuHeader > ul > li > ul > li a{
    padding: 3px 0;
    display: block;
    font-size: 12px;
    text-decoration: none;
    color: #707070;
}